import { HttpClient } from "../HttpClient";
import { isVVV } from "@/helpers/siteIdentifier";
/**
 * * Get Revenue Widgets
 */
export const GetRevenueWidgets = async (url, userId) => {
  try {
    const currentDashboard = isVVV() ? 3 : 2;
    const endpointUrl = userId
    ? `api/revenues/${url}?userId=${userId}&currentDashboard=${currentDashboard}&isImpersonation=true`
    : `api/revenues/${url}?currentDashboard=${currentDashboard}`;
    const { data } = await HttpClient.get(endpointUrl);
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
